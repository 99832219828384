import provideState_MaX9fQr3GV from "/home/library/www/frontend/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.js";
import revive_payload_client_4sVQNw7RlN from "/home/library/www/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/library/www/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/library/www/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/home/library/www/frontend/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/library/www/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/library/www/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/library/www/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/library/www/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_8lPoTBZJfw from "/home/library/www/frontend/.nuxt-creced/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/library/www/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/home/library/www/frontend/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import language_Zy8KLHw9oT from "/home/library/www/frontend/node_modules/nuxt-language-negotiation/dist/runtime/plugins/language.mjs";
import useDirectives_2KnF4DchlO from "/home/library/www/frontend/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/useDirectives.js";
import createBootstrap_Re1dxEZp3O from "/home/library/www/frontend/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/createBootstrap.js";
import graphqlMiddleware_2xI7SMabjg from "/home/library/www/frontend/plugins/graphqlMiddleware.ts";
import texts_P4dMAr3Ct0 from "/home/library/www/frontend/node_modules/nuxt-easy-texts/dist/runtime/plugins/texts.mjs";
import drupalUser_HxAWH89yLt from "/home/library/www/frontend/plugins/drupalUser.ts";
export default [
  provideState_MaX9fQr3GV,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_8lPoTBZJfw,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  language_Zy8KLHw9oT,
  useDirectives_2KnF4DchlO,
  createBootstrap_Re1dxEZp3O,
  graphqlMiddleware_2xI7SMabjg,
  texts_P4dMAr3Ct0,
  drupalUser_HxAWH89yLt
]